.menu {
    height: 100%;
    
    &_title {
      color: $secondary_color;
      font-size: 3rem;
  
      @include size_M {
        font-size: 1.75rem;
      }
  
      margin: 3rem auto;
    }
  
    &_container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      column-gap: 4rem;
      row-gap: 6rem;
      margin: 6rem 0;
  
      &_item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        border: 2px solid $secondary_color;
        border-radius: 25px;
        color: $secondary_color;
        width: 200px;
        height: 200px;
        cursor: pointer;
        opacity: 1;
        transition: 250ms ease-in-out;
  
        &_image {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          margin: -1rem;
  
          img {
            max-width: 50%;
            max-height: 50%;
          }
        }
  
        h2 {
          text-align: center;
          font-size: 1rem;
          font-weight: 600;
          margin: 0;
        }
  
        &:hover {
          opacity: 0.5;
        }
      }
    }
  
    &_subtitle {
      color: $secondary_color;
    }
  }
  