$transition_fast: 250ms ease;
$transition: 500ms ease;
$transition_slow: 1000ms ease;

$primary_color: #303036;
$secondary_color: #ffffff;
$complementary_color: #007b3f;
$danger: #ff7e6b;

:export {
  primaryColor: $primary_color;
  secondaryColor: $secondary_color;
  danger: $danger;
  complementaryColor: $complementary_color;
}
