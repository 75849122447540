.eat {
  height: 100%;

  h1 {
    color: $secondary_color;
    font-size: 3rem;
    margin: 3rem auto;

    @include size_M {
      font-size: 1.75rem;
    }
  }

  h2 {
    color: $secondary_color;
    font-size: 2rem;
    margin: 3rem auto;

    @include size_M {
      font-size: 1.5rem;
    }
  }

  &_container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 6rem;
    margin: 6rem 0;

    @include size_M {
      margin: 3rem 0;
      row-gap: 3rem;
    }

    &_card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
      border: 2px solid $secondary_color;
      border-radius: 25px;
      width: 370px;
      min-width: 260px;
      color: $secondary_color;

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
        margin-bottom: 2rem;
      }

      p {
        padding: 0;
        margin: 0;
        margin-top: 1rem;
      }

      &_web {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-top: 2rem;
      }
    }
  }
}
