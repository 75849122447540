@mixin size_XXL {
    @media (max-width: 1700px) { @content; }
}
@mixin size_XL {
    @media (max-width: 1300px) { @content; }
}
@mixin size_L {
    @media (max-width: 1100px) { @content; }
}
@mixin size_M {
    @media (max-width: 870px) { @content; }
}
@mixin size_S {
    @media (max-width: 650px) { @content; }
}
@mixin size_XS {
    @media (max-width: 500px) { @content; }
}